.orders-pie-chart {
  .recharts-legend-wrapper {
    top: 0px !important;
  }
  .legend-item {
    padding-bottom: 10px;
    display: flex;
    gap: 10px;
    color: var(--ion-color-dark-tint);
    font-weight: 600;

    .legend-indicator {
      width: 30px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 5px;
      font-weight: 600;
      border: 2px solid;
    }
  }

  .center-value {
    font-weight: 600;
    fill: var(--ion-color-dark);
    font-size: 1.2em;
  }
}