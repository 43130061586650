.onboarding-swiper {
  height: 100%;
}

.swiper-slide {
  font-size: 18px;
  background: var(--ion-color-light);
  overflow: auto;

  /* Center slide text vertically */
  display: flex;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
