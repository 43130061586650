.refund-btn {
  display: flex;
  gap: 5px;
}

.refund-modal-content {
  display: flex;
  align-items: center;
  gap: 10px;

  .ant-input-number {
    border: none;
    background: var(--ion-color-light);
    border-radius: 10px;
  }

  .item-price {
    color: var(--ion-color-medium);
    grid-column: 2;
    grid-row: 1/3;
    gap: 5px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}