.setup {
  .tiffin-application {
    width: 100%;
    height: 100%;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;

    .tiffin-logo {
      max-width: 100px;
  
      img {
        border-radius: 10px;
      }
    }

    h2 {
      font-weight: 600;
    }

    .main-text {
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: center;
    }

    .application-swiper {
      width: 100%;
      padding-bottom: 50px;
      
      .swiper-slide {
        width: 100%;
      }

      .slide-text {
        display: flex;
        flex-direction: column;
        gap: 5px;
        text-align: center;
        padding: 0px 20px;
        font-size: 1.2em;
        width: 100%;
      }
    }
  }
}