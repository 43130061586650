.home {
  ion-footer {
    position: fixed;
    bottom: 0px;
  }

  @media (orientation: portrait) {
    ion-content {
      --padding-bottom: 60px;
    }
  }
}

@media (orientation: landscape) {
  .home {
    display: grid;
    grid-template-columns: auto 1fr;
    height: 100%;

    .ant-menu-item {
      height: 80px !important;
    }

    ion-toolbar {
      --padding-start: 190px; 
    }

    ion-content {
      --padding-start: 170px;
    }
  }

  .home.menu-bar-collapsed {
    ion-content {
      --padding-start: 80px;
    }

    ion-toolbar {
      --padding-start: 100px; 
    }
  }
}