.top-items-card {
  height: 100%;
  overflow: hidden;
  
  .sort-switch {
    width: auto;
  }

  .ant-card-body {
    overflow: auto;
    padding-top: 0px;

    .ant-list {
      height: 100%;
    }
  }

  .left-panel {
    display: flex;
    flex-direction: column;
    color: var(--ion-color-dark-tint);

    .item-heading {
      font-weight: 600;
      font-size: var(--large-font-size);
    }

    .item-subheading {
      font-size: 1em;
    }
  }

  .right-panel {
    display: flex;
    flex-direction: column;
    color: var(--ion-color-dark-tint);
    text-align: right;

    .label {
      font-size: 1em;
    }

    .value {
      font-size: 1.4em;
      font-weight: 700;
    }
  }

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-spinning {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}