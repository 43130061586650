.reviews-card-list {
  .customer-review {
    padding: 10px 0;
    
    .heading {
      font-size: 0.875em;
    }

    .details {
      font-size: 0.75em;
    }
  }

  ion-item.no-data {
    --background: var(--ion-background-color);
    --color: var(--ion-color-medium);
    font-weight: 600;
    font-size: 14px;

    .no-reviews {
      padding: 20px 4px;
    }
  }
}