.dashboard-picker {
  display: flex;
  align-items: center;

  .ant-picker {
    visibility: hidden;
    width: 0px;
    padding: 0px;
    border: 0px;
  }
}