.order-content {
  display: grid;
  gap: 10px;

  &.full-view {
    grid-template-columns: 1fr 2fr;
    padding-right: 24px;
    gap: 0px;
  }

  .left-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .right-section {
    margin-left: 25px;
    padding-left: 20px;
    border-left: 1px solid var(--ion-color-medium);
  }

  .row {
    display: flex;
    justify-content: space-between;

    .flex-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      width: 100%;
    }
  }

  ion-button {
    font-size: var(--small-font-size);
  }

  .small-text {
    font-size: var(--small-font-size);
    color: var(--ion-color-dark);
    font-weight: 700;
  }

  .note-section {
    padding-bottom: 10px;

    .order-note {
      font-weight: 300;
    }
  }

  .btn-group {
    flex-grow: 1;
  }

  .order-buttons {
    gap: 5px;
    
    ion-button {
      margin: 0;
    }
  }

  .refund-buttons {
    display: flex;
    align-items: flex-end;

    ion-button {
      margin: 0;
    }
  }

  .message-btn {
    height: 20px;
    margin: 0px;

    ion-icon {
      font-size: var(--large-font-size);
    }
  }

  .order-items-totals-summary {
    color: var(--ion-color-dark);
  }
}