.order-details-breadcrumb {
  padding: 20px 20px 10px;
}

.order-details {
  padding: 0 20px;

  .order-header {
    display: grid;
    color: var(--ion-color-dark);

    .customer-name-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      @media (min-width:961px) {  
        justify-content: flex-start;
        gap: 20px;
      }

      .customer-name {
        font-size: 2em;
        font-weight: 600;
      }
    }

    .order-id {
      display: flex;
      align-items: flex-end;
      font-size: small;
    }
  }

  .order-date {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding-top: 5px;
    color: var(--ion-color-dark);
    align-items: center;
  }
  
  .order-tags {
    display: flex;
    gap: 2px;
    align-items: center;

    .tfn-pill {
      font-size: 1em;
      padding: 4px 12px;
    }
  }

  .cancel-reason {
    padding-top: 10px;
  }

  .customer-details {
    padding-top: 20px;
    display: grid;
    gap: 10px;
    font-size: 14px;

    .left-panel {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .middle-panel {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-bottom: 10px;
    }

    .right-panel {
      height: 200px;
      width: 100%;
      max-width: 500px;
    }

    .right-panel > div > div {
      border-radius: 10px;
    }
  }

  .ant-divider-horizontal {
    margin: 10px;
  }

  .info-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .info-label {
    color: var(--ion-color-dark);
    font-weight: 600;
  }
  
  .info-value {
    color: var(--ion-color-medium);
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .order-items {
    background-color: var(--ion-color-light);
    padding: 10px 20px;

    .order-details-item {
      display: grid;
      gap: 20px;
      align-items: center;

      .refund-item {
        margin: auto;
      }
    }
  }

  .order-summary {
    padding-top: 20px;
    font-size: 16px;

    .order-items-totals-summary .order-items-totals {
      gap: 10px;
    }

    .item-details {
      row-gap: 10px;

      .item-modifiers {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 10px;
                
        .item-modifier {
          gap: 5px;
        }
      }
    }

    .order-items-totals-summary {
      padding: 10px 0;
      color: var(--ion-color-dark);
    }

    .order-total {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: flex-end;
      justify-items: flex-end;
      column-gap: 20px;
      font-size: 1.2em;
      font-weight: 600;
      padding: 20px 10px;
    }
  
    .order-items-summary {
      background: var(--ion-color-light);
      padding: 20px;
  
      @media (min-width:961px) {
        font-size: 1em;
      }
    }
    
    .ant-divider-horizontal {
      margin: 0px;
    }
  
    .order-footer {
      display: grid;
      justify-content: space-between;
      gap: 10px;
  
      .refund-buttons {
        grid-column: 1;
      }
  
      .order-buttons {
        grid-column: 2;
      }
    }
  }
}

.address-card-marker  {
  font-size: 24px;
  margin-left: -12px;
  margin-top: -24px;
}

.custom-skeleton {
  display: grid;
  gap: 10px;
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:961px)  {  
  .order-details {
    .customer-details {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .order-date {
      justify-content: normal;
    }

    .order-summary {  
      .order-total {
        padding: 20px 0;
      }
    }
  }
}