.menu-content {
  background-color: var(--ion-color-light);
  height: 100%;
  padding: 10px;

  display: grid;
  grid-template-columns: 1fr;
  overflow-y: auto;

  .search-menu-input {
    grid-column: 1/3;
    margin-bottom: 10px;
    width: 300px;

    .ant-input {
      background: var(--ion-background-color);
    }
  }

  &.full-view {
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    
    .ant-menu {
      overflow: auto;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .ant-menu {
    border-radius: 8px;
    
    .ant-menu-item-group-title {
      color: var(--ion-color-dark-tint);
      font-weight: 900;
      font-size: var(--large-font-size);
    }

    .ant-menu-item-group-list {
      .ant-menu-item {
        padding-left: 18px !important;
        font-size: var(--reg-font-size);
      }

      .ant-menu-title-content {
        .menu-item {
          color: var(--ion-color-dark);
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .new-item {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }

  .menu-categories {  
    .add-category {
      .ant-menu-title-content {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    .ant-menu-item {
      height: 40px !important;
    }
  }
}

.menu-loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-item-modal {
  &.hide {
    visibility: hidden;
  }
}