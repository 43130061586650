.customer-review {
  display: grid;
  gap: 10px;
  width: 100%;
  color: var(--ion-color-dark-tint);
  
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .reviewer-name {
      font-size: 1.2em;
      font-weight: 600;
    }
  }

  .details {
    display: grid;
  }
}