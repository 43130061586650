.orders-chart {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;

  .current-time-indicator {
    display: flex;
    align-items: center;
    color: var(--ion-color-success);
    font-weight: 600;
    font-size: 0.75em;

    .blob {
      background: var(--ion-color-success);
      border-radius: 50%;
      margin: 5px;
      height: 0.875em;
      width: 0.875em;
    
      box-shadow: 0 0 0 0 rgba(var(--ion-color-success-rgb), 1);
      transform: scale(1);
      animation: pulse 2s infinite;
    }
    
    @keyframes pulse {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(var(--ion-color-success-rgb), 0.7);
      }
    
      70% {
        transform: scale(1);
        box-shadow: 0 0 0 5px rgba(var(--ion-color-success-rgb), 0);
      }
    
      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(var(--ion-color-success-rgb), 0);
      }
    }
  }

  .chart-container {
    height: 115px;
    width: 100%;
    overflow: hidden;

    .orders-tooltip {
      font-size: 0.75em;
      background-color: var(--ion-color-light);
      border-radius: 10px;
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .recharts-cartesian-axis-ticks {
      font-weight: 700;
    }
  }

  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    color: var(--ion-color-medium);
    font-weight: 600;
  }
}