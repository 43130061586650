.menu-item-modal {
  .ion-page {
    justify-content: unset;
  }

  .page-header {
    padding-top: calc(var(--ion-safe-area-top, 0) + var(--page-padding));
  }
}

.menu-item-editor-container {
  height: 100%;
  overflow: hidden;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  .item-footer {
    padding: 15px 20px calc(var(--ion-safe-area-bottom, 0) + 15px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    bottom: 0px;
    background: #fff;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.03);
    z-index: 2;
    gap: 10px;


    .right-footer-buttons {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .move-selector,
    .archive-category-selector {
      --placeholder-opacity: 0.8;
    }
  }
}

.menu-item-editor {
  background-color: var(--ion-background-color);
  padding: 20px 0px 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;

  .content-container {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .content-padding {
    padding: 0 20px;
  }


  .row1 {
    display: grid;
    column-gap: 20px;
    row-gap: 10px;
  }

  .row2 {
    display: grid;
    column-gap: 20px;
    row-gap: 10px;

    @media (min-width:768px)  {
      grid-template-columns: 1fr 1fr;
    }
  }

  .item-header {
    font-weight: 400;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;

    .item-name {
      flex-grow: 1;
    }
  }

  .item-divider {
    margin: 12px auto;
    min-width: unset;
    width: calc(100% - 40px);
  }

  .item-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    gap: 10px;
    text-align: left;
    overflow: hidden;

    .menu-image-upload .ant-upload {
      width: 175px;
      height: 150px;
    }
    
    .menu-image-upload .ant-upload-list-item-container{
      width: 150px !important;
      height: 150px !important;
    }

    .menu-image-upload .ant-upload-list > img {
      object-fit: contain;
    }
    
    .menu-image-upload > .ant-upload-list {
      display: flex;
    }
    
    .menu-image-upload .ant-upload-list-picture-card-container {
      width: 100%;
      height: 10rem;
    }
    
    .menu-image-upload .ant-upload-list-item-image {
      object-fit: cover !important;
    }
    
    .menu-image-upload .ant-upload.ant-upload-select-picture-card {
      width: 100%;
      height: 48px;
      margin: 0px;
    }

    .item-description {
      padding: 10px 20px;
      background: var(--ion-color-light);
      border: none;
      border-radius: 10px;
      font-size: small;
    }

    .info {
      padding: 5px 10px;
      font-size: var(--small-font-size);
      color: var(--ion-color-medium);
    }

    .subheading {
      font-family: var(--ion-font-family);
      font-size: small;
      padding: 10px 0px;
      color: var(--ion-color-dark);
      font-weight: 900;
    }

    .ant-input-number-input {
      font-size: small;
    }

    .note {
      font-size: 0.8em;
      padding: 10px 0px;
      color: var(--ion-color-medium-shade);
    }

    .ant-input-number {
      border: none;
      background: var(--ion-color-light);
      border-radius: 10px;
    }

    .item-price {
      display: flex;
      gap: 5px;
      align-items: center;
      font-size: 1em;
    }

    .dietary-attributes {
      display: flex;
      gap: 2px;

      .dietary-option-select {
        display: flex;
        align-items: center;
        padding-right: 2px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .item-oos {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    .item-oos-dates {
      display: flex;
      flex-wrap: wrap;
    }

    .item-availability {
      display: flex;
      gap: 10px;
    }

    .modifiers-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .advanced-options-panel {
      .ant-collapse-header {
        padding: 12px 0;
        font-size: small;
      }

      .ant-collapse-content-box {
        padding-block: 0;
        padding: 0;
      }
    }
  }
}

.select-dietary-dropdown {
  .dietary-option-select {
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}