.rating-chart {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;

  .ant-card-body {
    overflow: hidden;
    padding: 0;
    position: relative;
  }

  .center-value {
    font-weight: 400;
    fill: var(--ion-color-medium-shade);
    font-size: 2.4em;
  }

  .no-ratings {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--ion-color-medium);
    height: 100%;
  }
}