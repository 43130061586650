.account-info {
  .info-container {
    height: 100%;
    background-color: var(--ion-color-light);
    display: flex;
    justify-content: center;

    #edit-account {
      overflow: auto;
    }
  }

  #edit-account {
    width: 100%;
    height: 100%;
    background: var(--ion-background-color);
    padding: 20px;
  }

  #edit-account .ant-form-item {
    margin-bottom: 10px;
  }

  .policy-ack-checkbox {
    justify-content: flex-end;

    .internal-link {
      padding: 0;
    }
  }

  .info-form-buttons {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 5px;
    margin: 20px;
    justify-content: center;
    align-items: center;

    &.no-delete {
      grid-template-columns: 100%;
    }

    @media (min-width:576px)  {
      display: flex;
      margin-right: 8%;
      margin-left: 16%;
      justify-content: space-between;
      grid-template-columns: 1fr 1fr;

      ion-button {
        min-width: 120px;
      }

      &.no-delete {
        justify-content: flex-end;
        grid-template-columns: 100%;
      }
    }

    .edit-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .phone-input {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .phone-input {
    .ant-input-group-addon {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      background: var(--ion-color-light);
      border: 1px solid var(--ion-color-light-shade);
      border-right-width: 1px;
    }

    .ant-input {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  .ant-form-item-has-error :not(.ant-input-disabled).ant-input {
    background-color: var(--ion-color-light) !important;
    border: 1px solid var(--ion-color-danger);
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:961px)  {
  .account-info #edit-account {
    padding: 40px;
  }
  
  .account-info #edit-account.landscape {
    padding: 40px;
    width: 70%;
  }

  .account-info #edit-account.portrait {
    width: 80%;
  }
}

.alert-content {
  margin-bottom: 0;
}