.onboarding-status {
  display: flex;
  gap: 10px;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;

  .status-icon {
    font-size: 8em;
    display: flex;
  }

  .status-heading {
    font-size: 2em;
  }
}