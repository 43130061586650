.main-footer {
  display: flex;
  background-color: var(--ion-background-color);
  justify-items: center;
  font-size: 1.6em;
  overflow-x: auto;

  .footer-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 25px 0px 20px;
  }

  .footer-label {
    font-size: 0.5em;
    font-weight: 400;
  }

  div:active {
    background-color: var(--ion-color-light);
  }

  .selected {
    .ant-badge {
      color: var(--app-base-color)
    }
  }
}