.customer-reviews-card {
  height: 100%;
  overflow: hidden;

  .ant-card-body {
    overflow: auto;
    padding-top: 0px;

    .ant-list {
      height: 100%;
    }
  }

  .review-list {
    padding: 20px;
  }
}