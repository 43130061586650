.status {
  div.deactivate-options {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    gap: 40px;

    .deactivate-option {
      display: grid;
      gap: 10px;
    }
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:961px) {  
  .status {
    div.deactivate-options {
      padding: 0 30%;
    }
  }
}