.available-dates {
  height: 100%;

  .calendar {

    .ant-picker-cell-today {
      .calendar-cell {
        border-color: var(--ion-color-primary);
        background-color: rgba(var(--ion-color-primary-rgb), 0.1);
      }
    }

    .calendar-cell {
      display: grid;
      grid-template-columns: 1fr auto;
      height: 100px;
      border-top: 2px solid var(--ion-color-light);
      margin: 0px 5px;
      padding: 5px;
    }

    .calendar-header {
      font-size: 1.6em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding: 10px;
    }

    .calendar-content {
      color: var(--ion-color-medium);
      display: grid;
      font-size: 0.8em;
      text-align: left;

      .cell-heading {
        display: flex;
        gap: 5px;
        align-items: center;

        .cell-indicator {
          height: 8px;
          width: 8px;
          border-radius: 4px;
        }
      }

      .cell-timings {
        margin-left: 13px;
      }
    }
  }
}

.adjust-date-modal {

  ion-toolbar {
    --padding-top: 16px;
    --background: var(--ion-background-color);
    --border-color: var(--ion-background-color);
  }

  .unavail-modal-container {
    display: grid;
    padding: 16px;
    gap: 10px;
  }

  .avail-modal-container {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 10px;
    padding: 16px 16px 32px 16px;
  
    .btn-group {
      padding-top: 16px;
      display: grid;
  
      .inner-btn {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .availability-label {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  
    .cell-indicator {
      height: 8px;
      width: 8px;
      border-radius: 4px;
    }
  
    .closed-message {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  
    .timing-option {
      display: flex;
      flex-direction: column;
      gap: 10px;
  
      .label {
        color: var(--ion-color-dark);
      }
    }
  }
}

.available-time-picker {
  --ion-backdrop-opacity: 0.5;
}