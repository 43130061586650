.customer-reviews {
  ion-content {
    --background: var(--ion-color-light);
  }
  
  .main-content {
    height: 100%;
    padding: var(--page-padding);
    padding-bottom: calc(var(--ion-safe-area-bottom,0) + var(--page-padding));
    display: grid;
    gap: 10px;

    .reviews-card-list {
      padding-bottom: 20px;
    }

    @media (orientation: portrait) {
      padding-bottom: calc(var(--ion-safe-area-bottom, 0) + 10px);
    }
  }
}