@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

@import '~swiper/scss';
@import '~swiper/scss/pagination';
@import '~swiper/scss/navigation';

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/ProximaNova/ProximaNova-Regular.otf');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/ProximaNova/ProximaNova-Medium.otf');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/ProximaNova/ProximaNova-SemiBold.otf');
}

:root {
  --ion-font-family: 'Proxima Nova',  'Open Sans', sans-serif;
  --page-padding: 10px;
  --footer-height: 75px;
  --card-header-font-size: 1.4em;

  --xs-font-size: 0.75em;
  --small-font-size: 0.875em;
  --reg-font-size: 1em;
  --large-font-size: 1.2em;
  --xl-font-size: 1.6em;
}

.body {
  font-family: var(--ion-font-family);
}

/* To remove firebase emulator styling */
.firebase-emulator-warning { display: none; }

.fadeOut {
  opacity:0;
  z-index: -1;
  transition-timing-function: ease-out;
  transition: width 0.2s 0.2s, height 0.2s 0.2s, opacity 0.2s, z-index 0.2s 0.2s;
}

.fadeIn {
  opacity:1 !important;
  transition-timing-function: ease-out;
  transition: width 0.2s, height 0.2s, opacity 0.2s 0.2s;
}

.fadeOut2 {
  opacity:0 !important;
  z-index: -1 !important;
  height: 0px !important;
  width: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
  transition-timing-function: ease-in;
  transition: margin 0.2s 0.2s, padding 0.2s 0.2s, width 0.2s 0.2s, height 0.2s 0.2s, opacity 0.2s, z-index 0.2s 0.2s;
}

.fadeIn2 {
  opacity:1 !important;
  transition-timing-function: ease-out;
  transition: padding 0.2s 0.2s, width 0.2s, height 0.2s, opacity 0.2s 0.2s;
}

.page-header {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  font-size: 1.2em;
  font-weight: 400;
}

.back-button > .ant-btn {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 2;
  background-color: var(--app-base-color);
  border-color: var(--app-base-color);
}

.loading-btn {
  color: #fff;
  margin-right: 10px;
}

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--ion-color-light-rgb),0.5);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

//Common Antd Component styling
.table-striped-rows tr:nth-child(2n) td {
  background-color: var(--ion-color-light);
}
.table-striped-rows thead {
  background-color: var(--ion-color-light-tint);
}

.dashboard-card {
  display: grid;
  grid-template-rows: auto 1fr;

  .ant-card-head {
    font-size: 1em;

    .ant-card-head-wrapper {
      margin: 10px 0;
    }

    .ant-card-head-title {
      font-size: var(--card-header-font-size);
      font-weight: 600;
      color: var(--ion-color-dark-tint);
      display: flex;
      flex-wrap: wrap;
      column-gap: 20px;
      justify-content: space-between;
      align-items: center;
    }
  }

  .right-title-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

//Common Ion component styling
ion-content{
  --keyboard-offset: 0 !important;
}

ion-item {
  font-size: 1em;
}

ion-button[type=submit] {
  appearance:         none;
  -moz-appearance:    none;
  -webkit-appearance: none;
}

ion-segment-button {
  font-weight: 600;
}

ion-button {
  text-transform: none;
}

ion-action-sheet {
  .action-sheet-title {
    font-weight: 600;
    --color: #000;
  }

  .action-sheet-subtitle {
    font-weight: 300;
  }

  button {
    font-weight: 600;
  }
}

//Overriding antd styling
button.ant-btn-link {
  padding: 0;
  font-size: 1em;
}

textarea.ant-input,
div.ant-input,
input.ant-input {
  padding: 10px 20px;
  background: var(--ion-color-light);
  border: none;
  border-radius: 10px;

  &::placeholder {
    color: var(--ion-color-medium);
  }
}

.ant-list,
.ant-list-item,
.ant-card,
.ant-menu,
.ant-collapse {
  font-family: var(--ion-font-family);
}

.ant-form-item {
  .ant-form-item-explain-error {
    font-weight: 600;
    color: var(--ion-color-danger);
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: var(--ion-safe-area-top);
  padding-bottom: var(--ion-safe-area-bottom);
  padding-left: var(--ion-safe-area-left);
  padding-right: var(--ion-safe-area-right);

  .main-content {
    flex-grow: 1;

    @media (orientation: portrait) {
      padding-bottom: var(--footer-height);
    }
  }
}

.page-breadcrumb-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding: 20px 20px 10px;
}