.payout-card {
  display: grid;
  width: 100%;
  padding: 10px 5px;
  color: var(--ion-color-medium);
  font-size: var(--reg-font-size);

  .card-header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 20px;
    justify-content: space-between;
    align-items: center;

    .payout-title {
      display: grid;

      .desc {
        font-size: var(--large-font-size);
        font-weight: 600;
        text-transform: lowercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    
      .desc::first-letter {
        text-transform: uppercase;
      }

      .type {
        font-size: var(--small-font-size);
        color: var(--ion-color-medium);
      }
    }

    .negative-amount {
      display: flex;
      align-items: center;
      gap: 5px;
      color: var(--ion-color-danger)
    }

    .status-tag {
      color: var(--ion-color-light);
      font-weight: 600;
      margin: 0px;
    }
  }

  .card-content {
    padding: 10px 0px;
    display: grid;
    grid-template-columns: 3fr 2fr;

    .subheading {
      font-weight: 600;
    }

    .left-panel {
      display: flex;
      flex-direction: column;
    }

    .right-panel {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
}