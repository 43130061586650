.order-buttons {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  align-items: flex-end;

  .btn-content {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  ion-spinner {
    margin-right: 5px;
    height: 1.2em;
  }
}