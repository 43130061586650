.partner-order-card-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: var(--ion-background-color) !important;
  overflow: auto;
  border-radius: 8px;

  .current-time-indicator {
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    align-items: center;
    color: var(--ion-color-success);
    font-weight: 600;
    font-size: 0.9em;

    .left-divider {
      width: calc(100% - 10px);
      border: 0.5px solid;
      margin: 0px 5px;
      min-width: 20px;
    }

    .right-divider {
      width: calc(100% - 10px);
      border: 0.5px solid;
      margin: 0px 5px;
    }

    .blob {
      background: var(--ion-color-success);
      border-radius: 50%;
      margin: 10px;
      height: 0.75em;
      width: 0.75em;
    
      box-shadow: 0 0 0 0 rgba(var(--ion-color-success-rgb), 1);
      transform: scale(1);
      animation: pulse 2s infinite;
    }
    
    @keyframes pulse {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(var(--ion-color-success-rgb), 0.7);
      }
    
      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(var(--ion-color-success-rgb), 0);
      }
    
      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(var(--ion-color-success-rgb), 0);
      }
    }
  }

  .group-heading {
    font-size: 14px;
    padding: 0 10px 5px;
    color: var(--ion-color-dark-tint);
    background: #fff;

    display: flex;
    justify-content: space-between;
    align-items: center;

    font-weight: 900;

    @media (min-width:961px)  {
      font-size: 16px;
    }
  }

  .expand-toggle {
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: normal;
    font-size: small;

    ion-toggle.ios {
      --handle-height: 16px;
      --handle-width: 16px;
      height: 20px;
      width: 40px;
      padding: 0px;
    }
  }

  .order-type-filter {
    padding: 0 0 10px 10px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    row-gap: 16px;
    flex-direction: row;
    
    .type-value {
      padding: 0px 20px;
    }

    .completed-checkbox {
      font-size: small;
    }

    .order-type-select {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-collapse {
    display: grid;
    gap: 5px;
    background-color: var(--ion-background-color) !important;
  }

  .partner-order-card {
    border-bottom: none !important;
    margin-bottom: 5px;
    background-color: var(--ion-card-background);

    .ant-collapse-arrow {
      color: var(--ion-color-medium);
    }

    .ant-collapse-header,
    .ant-collapse-content-box {
      background: var(--ion-color-very-light);
    }
  }

  .empty-message {
    flex-grow: 1;
    background: var(--ion-background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 8px;
    padding-bottom: 40px;

    svg {
      width: 100px;
      height: fit-content;
    }
  }
}