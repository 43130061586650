.menu-bar.menu-bar-collapsed {
  width: 80px;
  transition: width 0.2s;

  .ant-menu {
    .ant-menu-item  {
      display: grid;
      grid-template-columns: 1fr !important;
    }
  }
}

.menu-bar {
  width: 170px;
  height: 100%;
  transition: width 0.2s;
  overflow: auto;
  z-index: 999;
  padding-top: var(--ion-safe-area-top);

  .ant-menu {
    position: relative;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    background: #303030;
    color: var(--ion-color-dark-contrast);

    .ant-menu-item  {
      display: grid;
      grid-template-columns: auto 1fr;

      .ant-scroll-number-only {
        margin-top: 3px;
      }

      &:not(.ant-menu-item-selected):hover {
        background: rgba(255,255,255,0.5);
      }

      &.ant-menu-item-selected {
        background: rgba(255,255,255,0.3);
        color: #fff;
      }

      &.ant-menu-item-active {
        background: rgba(255,255,255,0.5);
        color: #fff;
      }
    }

    .bottom-item {
      margin-top: auto;
    }
  }

  .menu-item {
    ion-icon {
      font-size: 1.4em !important;
    }
  }

  .menu-item-collapsed {
    display: grid;
    grid-template-rows: 100%;
    align-items: center;
    justify-items: center;
    padding: 0px !important;
  }
}