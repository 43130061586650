/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {

  --app-base-color: #096dd9;
  --app-base-contrast: #ffffff;

  --ion-background-color: #fff;
  --ion-header-color: #ffffff;

  --antd-primary-color: #3880ff;

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #428cff;
  --ion-color-secondary-rgb: 66,140,255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #3a7be0;
  --ion-color-secondary-tint: #5598ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
	--ion-color-success: #6abe39;
	--ion-color-success-rgb: 106,190,57;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #5da732;
	--ion-color-success-tint: #79c54d;

  /** warning **/
  --ion-color-warning: #ff7a45;
  --ion-color-warning-rgb: 255,122,69;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e06b3d;
  --ion-color-warning-tint: #ff8758;

  /** danger **/
	--ion-color-danger: #e84749;
	--ion-color-danger-rgb: 232,71,73;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cc3e40;
	--ion-color-danger-tint: #ea595b;

  --ion-color-dark: #404040;
  --ion-color-dark-rgb: 64,64,64;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #383838;
  --ion-color-dark-tint: #535353;

  --ion-color-medium: #808080;
  --ion-color-medium-rgb: 128,128,128;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #717171;
  --ion-color-medium-tint: #8d8d8d;

  --ion-color-light: #f0f0f0;
  --ion-color-light-rgb: 240,240,240;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d3d3d3;
  --ion-color-light-tint: #f2f2f2;

	--ion-color-very-light: #f8f8f8;
	--ion-color-very-light-rgb: 248,248,248;
	--ion-color-very-light-contrast: #000000;
	--ion-color-very-light-contrast-rgb: 0,0,0;
	--ion-color-very-light-shade: #dadada;
	--ion-color-very-light-tint: #f9f9f9;

  /* Tifyn specific variables */
  --full-refund-color: #ff7a45;
  --partial-refund-color: #ffa940;
}