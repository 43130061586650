.menu {
  padding-top: var(--ion-safe-area-top, --page-padding);
  padding-bottom: calc(var(--ion-safe-area-bottom,0) + var(--page-padding));

  @media (orientation: portrait) {
    padding-bottom: calc(var(--ion-safe-area-bottom,0) + var(--page-padding) + var(--footer-height));
  }

  .menu-tabs {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;

    .ant-tabs {
      width: 100vw;
      overflow: hidden;

      .ant-tabs-tabpane {
        height: 100%;
      }

      @media (min-width:768px)  {
        width: 100%;
      }
    }

    .ant-tabs-nav {
      margin: 0;
    }

    .extra-tab-buttons {
      display: flex;
      align-items: center;
      gap: 5px;

      .label {
        padding-left: 5px;
      }

      .unsaved-changes {
        font-size: 0.875em;
        gap: 5px;

        ion-icon {
          margin: 0;
        }
      }
    }

    .menu-buttons {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
    }

    .ant-tabs-nav {
      padding: 20px;
      display: flex;
      gap: 10px;

      .ant-tabs-tab {
        font-size: var(--large-font-size);
      }
    }

    .ant-tabs-content {
      height: 100%;
    }
  }
}

.menu-editor-modal {
  .ion-page {
    justify-content: flex-start
  }
}