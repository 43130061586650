.weekly-card {
  height: 100%;
  position: relative;

  .ant-card-body {
    padding-top: 0px;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: grid;
    align-items: center;
    justify-items: center;
    color: var(--ion-color-dark-tint);

    .main-stat {
      font-size: var(--xl-font-size);
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .secondary-stat {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .ant-card-body {
    position: relative;
  }
}