.revenue-chart {
  position: relative;
  background: var(--ion-background-color);
  padding: 16px 24px;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;

  .right-title-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .chart-title {
    font-size: var(--card-header-font-size);
    font-weight: 600;
    color: var(--ion-color-dark-tint);
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chart-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    color: var(--ion-color-medium);
    font-weight: 600;
  }

  .chart-loading {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    background-color: rgba(var(--ion-color-light-rgb),0.5);
  }
}