.partner-order-header {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  color: var(--ion-color-dark);
  font-size: 1em;
  width: 100%;
  font-weight: 600;

  @media (min-width:768px)  {
    grid-template-columns: 1fr auto;
  }

  .left-header {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .header-customer-name {
      display: flex;
      align-items: center;

      .header-loading {
        height: 1.2em;
        color: var(--ion-color-medium);
      }
    }

    .header-pills {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
    }
  }

  .right-header {
    display: flex;
    align-items: center;
    gap: 10px;
    
    .item-count {
      font-size: 0.75em;
    }
  }
}