.category-details {
  background-color: var(--ion-background-color);
  display: flex;
  flex-direction: column;
  text-align: left;

  .category-divider {
    margin: 12px 0px;
  }

  .category-content {
    display: grid;
    gap: 10px;
  }

  .category-footer {
    padding-top: 20px;
    display: flex;

    &.new-btns {
      justify-content: flex-end;
    }

    &.update-btns {
      justify-content: space-between;
    }

    ion-button {
      justify-self: flex-end;
    }
  }
}