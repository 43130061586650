.income-table {
  height: 100%;

  .expanded-row {
    display: flex;
    flex-direction: column;
    font-size: small;
    gap: 5px;

    .info {
      display: grid;
      grid-template-columns: 1fr 110px;

      .heading {
        text-align: right;
        font-weight: 600;
      }

      .value {
        padding: 0 8px;
      }
    }

    .view-order {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 50px;
    }
  }

  .ant-table-summary {
    font-weight: 600;
  }

  .incoming-balance {
    transform: rotate(45deg);
    color: var(--ion-color-success);
  }

  .outgoing-balance {
    transform: rotate(-135deg);
    color: var(--ion-color-danger);
  }

  .date-col-header {
    display: flex;
    gap: 2px;
    align-items: center;

    ion-icon {
      font-size: 1.2em;
      color: var(--ion-color-medium);
    }
  }
}