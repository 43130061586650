.partner-edit-tabs {
  min-height: 100%;
  background-color: var(--ion-color-light);
  display: flex;
  justify-content: center;

  .ant-tabs-nav-wrap {
    padding: 0px 20px;
  }

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-content {
    height: 100%;
  }
}