.login {
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .tiffin-logo {
    max-width: 100px;

    img {
        border-radius: 10px;
    }
  }

  > div {
    display: flex;
    gap: 10px;
    flex-direction: column;
	  align-items: center;
    justify-content: center;
  }
}