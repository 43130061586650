.dashboard {
  background-color: var(--ion-color-light);
  padding: var(--page-padding);
  padding-top: calc(var(--ion-safe-area-top,0) + var(--page-padding));
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  gap: 10px;
  overflow: auto;
  padding-bottom: calc(var(--ion-safe-area-bottom,0) + var(--page-padding));

  @media (orientation: portrait) {
    padding-bottom: calc(var(--ion-safe-area-bottom,0) + var(--page-padding) + var(--footer-height));
  }

  .metric-cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    .total-stat {
      .ant-statistic-title {
        font-weight: 600;
      }
    }
  }

  .subheading {
    color: var(--ion-color-dark-tint);
    padding: 0px 10px;
    font-weight: 600;
    font-size: var(--xl-font-size);

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .weekly-card {
    display: grid;
    gap: 10px;
  }

  .first-row {
    .no-orders {
      display: flex;
      height: 100%;
      width: 100%;
      padding: 20px;
      align-items: center;
      justify-content: center;
      font-size: var(--large-font-size);
      color: var(--ion-color-dark);
      background-color: var(--ion-background-color);
    }

    .upcoming-orders {
      display: flex;
      gap: 10px;
      width: 100%;
      height: 100%;
      overflow-x: auto;
      border-radius: 8px;

      &.stacked {
        flex-direction: column;
      }
    }
  }

  .second-row {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .weekly {
      display: grid;
      gap: 10px;
    }

    .dashboard-revenue-chart {
      grid-column: 2/4;
      min-height: 300px;
      min-width: 300px;
      overflow: hidden;
      border-radius: 8px;
    }
  }

  .third-row {
    display: grid;
    grid-template-rows: minmax(300px, 1fr) minmax(300px, 1fr)  minmax(300px, 1fr);
    gap: 10px;
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:961px)  {
  .dashboard {
    grid-template-rows: auto minmax(250px, auto) minmax(0, 350px)  minmax(0, 350px);
    min-height: calc(100vh - var(--ion-safe-area-top,0) - var(--ion-safe-area-bottom,0));
    height: auto;

    .metric-cards {
      grid-template-columns: repeat(4, 1fr);
    }

    .second-row {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 10px;
    }

    .third-row {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: 100%;
      gap: 10px;
    }
  }
}

@media (min-height:961px) {
  .dashboard {
    height: 100%;

    @media (min-width:961px)  {
      grid-template-rows: auto minmax(250px,auto) minmax(0,425px) minmax(0,425px);
    }
  }
}