.no-archived-items {
  background-color: var(--ion-background-color);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.6em;
  color: var(--ion-color-medium-shade);
  position: absolute;
  width: 100%;
  z-index: 1000;
}